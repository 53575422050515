<template>
  <div id="page-notfound">
    <img :src="pageNotFound" alt="PageNotFound">
  </div>
</template>

<script>
export default {
  name: 'page-notfound',
  data () {
    return {
      pageNotFound: 'https://mrlinblog-1256212550.cos.ap-guangzhou.myqcloud.com/blog/404.jpg'
    }
  }
}
</script>

<style lang="stylus" scoped>
#page-notfound
  min-height: calc(100vh - 160px)
  position: relative
  img
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto
    max-width: 100%
</style>
